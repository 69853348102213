import { PropsWithChildren, useEffect, useState } from 'react'
import clsx from 'utils/clsx'
import Script from 'next/script'
import Backdrop from '@integration-app/ui/Backdrop'
import Button, { ButtonProps } from '@integration-app/ui/Button'
import { useTrackingContext } from 'contexts/tracking-context'
import classes from './CalendlyWidget.module.css'

const calendlyUrl =
  'https://calendly.com/integration-app/30-min-demo?hide_gdpr_banner=1&embed_domain=calendly-embed.com&embed_type=PopupText&hide_event_type_details=1'

export function CalendlyInlineWidget() {
  const { addTrackingToUrl } = useTrackingContext()
  return (
    <div className={classes.calendlyWidget}>
      <div
        className={clsx(
          'calendly-inline-widget',
          classes.calendlyWidget_iframe,
        )}
        data-url={addTrackingToUrl(calendlyUrl)}
      />
      <Script src={'https://assets.calendly.com/assets/external/widget.js'} />
    </div>
  )
}

export function CalendlyPopupButton({
  open,
  children = 'Schedule Demo',
  ...props
}: ButtonProps & PropsWithChildren<{ open?: boolean }>) {
  const [openCalendly, setOpenCalendly] = useState(false)
  const { addTrackingToUrl } = useTrackingContext()

  useEffect(() => {
    setOpenCalendly(!!open)
  }, [open])

  return (
    <>
      <Button {...props} onClick={() => setOpenCalendly(true)}>
        {children}
      </Button>
      <Popup open={openCalendly} onClose={() => setOpenCalendly(false)}>
        <iframe
          src={addTrackingToUrl(calendlyUrl)}
          allowFullScreen
          className={classes.iframe}
        ></iframe>
      </Popup>
    </>
  )
}

function Popup({
  open,
  onClose,
  children,
}: PropsWithChildren<{ open?: boolean; onClose: () => void }>) {
  if (!open) return null
  return (
    <Backdrop onClick={onClose} className={classes.backdrop}>
      <div className={classes.popup}>{children}</div>
    </Backdrop>
  )
}
